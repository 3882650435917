// GraphQLのためのユーティリティ関数・型定義群

import type { GqlSdkFuncs } from '#gql'
import { createError, type useAsyncGql } from '#imports'

// Dashboard APIの型のエイリアス
export type GqlOperations = Parameters<typeof useAsyncGql>[0]
export type GqlVariables<Op extends GqlOperations> = Parameters<GqlSdkFuncs[Op]>['0']
export type GqlResult<Op extends GqlOperations> = Awaited<ReturnType<GqlSdkFuncs[Op]>>

// GraphQLはon-demandにデータを取得できるため、深いデータ構造を取ることが多いため
// その展開のためのユーティリティをいくつか提供している:

export type GqlUnion = { __typename: string }
export type GqlSelect<U, T extends string> = U & { __typename: T }

// __typename による特定の要素のselect
export function gqlSelect<U extends GqlUnion, T extends U['__typename']>(union: U | null | undefined, key: T): GqlSelect<U, T> | null {
  return union?.__typename == key ? union as GqlSelect<U, T> : null
}

// __typename による特定の要素のselect or throw createError
export function gqlForceSelect<U extends GqlUnion, T extends U['__typename']>(union: U | null | undefined, key: T): GqlSelect<U, T> {
  if (union?.__typename !== key) throw createError({ statusCode: 404, statusMessage: 'Not Found' })
  return union as GqlSelect<U, T>
}

export type GqlConnection<T> = { edges?: ({ node?: T | null } | null)[] | null }
export type GqlNodes<T extends GqlConnection<unknown>> = NonNullable<NonNullable<NonNullable<NonNullable<T['edges']>[0]>['node']>>[]

// Connection<T> -> T[]
export function gqlNodes<T extends GqlConnection<unknown>>(conn: T): GqlNodes<T> {
  // GraphQL Connectionの仕様上これらはnullableとなっているが必ずデータは返る
  return conn.edges!.map(edge => edge!.node!)
}

// ページネーションの情報を保持する型定義
export type GqlPageInfo = {
  startCursor?: string | null
  endCursor?: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
}
